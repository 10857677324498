<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

    <div class="logo" (click)="redirect()">
        <img class="logo-icon" src="assets/images/logos/pitstop-white.png">
        <span class="logo-text secondary-text">AUTOVERSE</span>
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground" fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <!-- <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

        <div class="h3 username">Charlie Adams</div>
        <div class="h5 email hint-text mt-8">adams.charlie@mail.com</div>
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <img class="avatar" src="assets/images/avatars/Velazquez.jpg">
        </div>

    </div> -->

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
        
        
    </div>
    
    <!-- <button style="background-color: #2d323e;border: none;color: #fff;float: left; cursor: pointer;" (click)="logout();">
        <mat-icon>exit_to_app</mat-icon> 
        <span style="padding-left: 5px;position: relative;bottom: 5px;">Logout</span>
    </button> -->
    <button style="background-color: #2d323e;border: none;color: #fff;float: left; cursor: pointer;    display: flex;margin-left: 16px;" (click)="logout();">
        <mat-icon style="height: 31px;">exit_to_app</mat-icon> 
        <span style="padding-left: 19px;position: relative;bottom: -3px;" (click)="logout()">Logout</span>
    </button>

</div>