<div class="container">
  <div class="outer-box">
    <div class="header">
      <div class="headerSearch">
        <div class="search-container">
          <input
            type="text"
            placeholder="Search by PO ID, Make, Model, Registration Number, Status"
            class="search-box"
            [(ngModel)]="searchText"
            (input)="filterOrdersBySearch()"
          />
          <button *ngIf="searchText" mat-icon-button class="clear-icon" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
          </button>
          <mat-icon matPrefix>search</mat-icon>
        </div>
        <div [formGroup]="filterForm" class="water">
          <mat-form-field appearance="none" class="minimal-date-picker">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <input matInput [matDatepicker]="picker" formControlName="date" hidden />
          </mat-form-field>
        </div>
        
      </div>
    </div>

    <div class="order-list-container">
      <div *ngIf="filteredOrders.length> 0; else noOrders">
        <div class="order-item" *ngFor="let order of filteredOrders">
          <div class="order-list">
            


            <div class="order-logo">
              <img *ngIf="order.make; else noLogo" 
              [src]="'https://cube.getpitstop.com/assets/img/car_make/' + order.make.replace(' ', '').toLowerCase() + '.png'" 
              alt="Logo" 
              class="logo-image">
              <ng-template #noLogo>
                <mat-icon class="logo-image">directions_car</mat-icon>
              </ng-template>
              
         
              
              <!-- <img *ngIf="order.make"   [src]="'https://cube.getpitstop.com/assets/img/car_make/' + order.make.replace(' ', '').toLowerCase() + '.png'" alt="Logo" class="logo-image">
               -->
              <div class="" style="float:right;">
                <p [ngClass]="{
      'cancelled': order.supplierStatus === '0',
      'created': order.supplierStatus === '1',
      'confirmed': order.supplierStatus === '2',
      'ready-to-pick': order.supplierStatus === '3',
      'dispatched': order.supplierStatus === '4'
    }"
  >
    {{ getStatusName(order.supplierStatus) }}
  </p>
  
              </div>
            </div>
            <div class="order-info-box">
              <p class="order-id">POID#{{ order.id?order.id:"NA"}} </p>
              
              <p class="order-model-fuel" style="font-weight:bold;">
                {{ order.model?order.model:"NA" }}, {{ order.fuelType?order.fuelType:"NA"}}
              </p>
              <p>{{order.variant?order.variant:"NA"}},[{{ order.manufacturingYear?order.manufacturingYear:"NA" }}]</p>
              <p>{{ order.registraionNumber?order.registraionNumber:"NA" }}</p>
            </div>
          </div>
          <div style="float: left; width: 100%;">
              <p class="order-item-name">{{ order.itemNames }}</p>
          
              <hr />
            <div class="order-footer">
              <p>Placed on: {{ order.created | date: 'dd MMM yyyy, hh:mm a' }}</p>
              <a 
              class="view-details" 
              (click)="handleViewDetails(order.orderKey)" 
             
            >
              View Details
              <mat-icon *ngIf="!isMobile">play_arrow</mat-icon>
            </a>
            
            </div>
          </div>
        </div>
      </div>
      <ng-template #noOrders>
        <div class="no-orders-container">
          <img src="assets/images/no-cart.png" alt="No Orders" class="no-orders-image" />
          <p class="no-orders-text" style="font-weight: bold;">No Purchase Order Found</p> 
          <p class="no-orders-text">No orders found for the selected date.</p>
          <button class="request-button" (click)="goToMyRequest()">Go to Purchase Orders</button>
        </div>
      </ng-template>
    </div>
  </div>
</div>