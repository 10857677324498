import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from '../globals';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  paramData: any;

  constructor(private http: HttpClient,
    private router: Router,
  ) {}

getOTP(mobile: string) {
  this.paramData = {
    mobile: mobile
  };
  return this.http.post(`${GlobalVariable.BASE_API_URL_VENDOR}/login/getOtp`, this.paramData);
}

login(mobile, otp){
  this.paramData = {
    otp: otp,
    mobile: mobile
    
  };
  return this.http.post(`${GlobalVariable.BASE_API_URL_VENDOR}/login/validateOtp`, this.paramData);
}
thisCheckLogin(){
  let spareLoginToken = localStorage.getItem('zohoId');
  if(!spareLoginToken && spareLoginToken!=null){
    this.router.navigate(['login']);
  }else{
    this.router.navigate(['home']);
  }
}

checkLogin(){
  let spareLoginToken = localStorage.getItem('accessToken');
  if(!spareLoginToken){
    this.router.navigate(['login']);
  }
}

logout(){
  localStorage.removeItem('vendorKey');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  sessionStorage.clear();


  this.router.navigate(['login']);
}
}
