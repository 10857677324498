import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from '../globals';
@Injectable({
  providedIn: 'root',
})
export class ListPageService {
  constructor(private http: HttpClient) {
  }
  

  getOrderList(vendorKey: string) {
   
    const paramData = {
      vendorKey: vendorKey,
    };

    return this.http.post(
        `${GlobalVariable.BASE_API_URL_VENDOR}/order/getAllOrders`,paramData);
    }
}
