import { NgModule } from '@angular/core';
import { RemovewhitespacesPipe } from './removewhitespaces.pipe';
import { FilterNames } from './filter-names';
import { FilterBrand } from './filter-brand';
import { LoginComponent } from './login/login.component';
import { ListPageComponent } from './list-page/list-page.component';
import { DetailsPageComponent } from './details-page/details-page.component';
@NgModule({
  imports: [
  ],
  declarations: [
    RemovewhitespacesPipe,
    FilterNames,
    FilterBrand,
    // DetailsPageComponent,
    // LoginComponent,
    // ListPageComponent,

  ],
  exports: [RemovewhitespacesPipe, FilterNames, FilterBrand]
})
export class ApplicationPipesModule {
}
