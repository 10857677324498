import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from '../globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DetailsPageService {

  uploadInvoice(filenames: string[]) {
    throw new Error('Method not implemented.');
  }
  paramData: { 
    vendorKey:any;
    orderKey:any;
    supplierStatus:any;
    invoiceNumber:any;
    invoicePath:any;
    invoiceDate:any;
    
   };

  constructor(private http:HttpClient) { }

  getDetails(orderKey) {

    return this.http.get(`${GlobalVariable.BASE_API_URL_VENDOR}`+'/order/orderDetails/'+orderKey);
  }

  getStatus(orderKey,supplierStatus)
{
    const paramData=
    {
      orderKey:orderKey,
      supplierStatus:supplierStatus
    }
    return this.http.post(`${GlobalVariable.BASE_API_URL_VENDOR}`+`/order/orderStatus`,paramData)
  }
 

  orderStatus(orderKey: string, supplierStatus: string) {

    const paramData = {
      orderKey,
      supplierStatus
    };
    return this.http.post(
      `${GlobalVariable.BASE_API_URL_VENDOR}/order/orderStatus`,
      paramData
    );
  }
  updateInvoice(vendorKey: string, orderKey: string, invoiceNumber: string, invoicePath: string, invoiceDate: string): Observable<any> {
   
    const paramData = {
      vendorKey,
      orderKey,
      invoiceNumber,
      invoicePath,
      invoiceDate
    };
  

  
    const apiUrl = `${GlobalVariable.BASE_API_URL_VENDOR}/order/uploadRetailerInvoice`;
  
    return this.http.post(apiUrl, paramData);
  }
  deleteInvoice(orderKey,retailerInvoicePath)
  {

    const paramData=
    {
      orderKey:orderKey,
      retailerInvoicePath:retailerInvoicePath
    }
    return this.http.post(`${GlobalVariable.BASE_API_URL_VENDOR}`+`/order/delete/invoice`,paramData)
  }
  updateImage(orderKey,images)
  {
 
    const paramData=
    {
      orderKey:orderKey,
      images:images
    }
    console.log("images--",paramData);
    return this.http.post(`${GlobalVariable.BASE_API_URL_VENDOR}`+`/order/upload/images`,paramData)
  }
  deleteImage(orderKey,images:string)
  {
 
    const paramData={
    orderKey:orderKey,
    images:images
    }

    return this.http.post(`${GlobalVariable.BASE_API_URL_VENDOR}`+`/order/delete/images`,paramData)
  }
  
}

